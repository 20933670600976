import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';

@Store
export class InstagramStore extends BaseStore {
  /**
   * Instagram media keeper
   */
  @Field
  private media: any[]; // TODO: add better type definition

  public async fetchInstagramFeed(endpoint: string) {
    let res: any; // TODO: add better type definition

    try {
      res = await fetch(endpoint, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
      }).then((res) => {
        return res.json();
      });
    } catch (e) {
      console.error('Instagram Store (fetchInstagramFeed): ', e);
    }

    if (res && res.result) {
      this.media = [...(this.media || []), ...res.result];
    }

    return this.media;
  }

  /**
   * Instagram media getter
   */
  public get mediaFeed() {
    return this.media || [];
  }
}
