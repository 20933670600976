import { Component, BaseComponent, Prop } from '@zento-lib/components';
import { decodePhpHtmlString } from '@zento-lib/util/html';
import type { InstagramData } from 'theme/stores/cms/types';
import { InstagramStore } from 'theme/stores/instagram/instagram';

import { InnerHTML } from '../../../atom/InnerHTML';

import style from './style.scss';
import { IInstagram } from './Instagram.d';

interface IInstagramExtras {
  feed: Array<{ id: string; permalink: string; caption: string; media_url: string }>;
}

/**
 * Instagram
 *
 * Instagram component allowing to show a list with images.
 **/
@Component({})
export class Instagram extends BaseComponent<IInstagram, IInstagramExtras> {
  /**
   * An object containing component data (heading title and components links and images)
   */
  @Prop({ type: Object, required: true })
  instagramData: InstagramData;

  private instagramStore = new InstagramStore();

  data() {
    return {
      endpoint:
        'https://graph.instagram.com/me/media?access_token=' +
        this.instagramData.token +
        '&fields=media_url,media_type,caption,permalink,thumbnail_url',
    };
  }

  /**
   * Fetches instagram data
   */
  async fetchInstagram() {
    if (!this.feed.length) {
      return await this.instagramStore.fetchInstagramFeed(this.$data.endpoint);
    }
  }

  /**
   * Fetch instagram on server side
   */
  public async serverPrefetch() {
    return await this.fetchInstagram();
  }

  /**
   * Determines instagram media feed
   */
  get feed() {
    return this.instagramStore.mediaFeed;
  }

  async mounted() {
    return await this.fetchInstagram();
  }

  render() {
    return (
      <section class={style.instagramBox}>
        <div class={style.container}>
          {this.instagramData.heading ? (
            <h2
              class={{
                [style.centerTitle]: this.instagramData.heading_position === 'center',
                [style.rightTitle]: this.instagramData.heading_position === 'right',
              }}
              key='top-instagram-title'>
              {this.instagramData.heading}
            </h2>
          ) : null}
          {this.instagramData.hashtag ? <InnerHTML contents={decodePhpHtmlString(this.instagramData.hashtag)} /> : null}
        </div>

        <ul class={style.instagram}>
          {this.feed.slice(0, parseInt(this.instagramData.imageLimit)).map((media) => {
            return (
              <li key={media.id}>
                <a href={media.permalink} title={media.caption} target='_blank' rel='noopener'>
                  <img
                    src={media.media_type === 'VIDEO' ? media.thumbnail_url : media.media_url}
                    alt={media.caption}
                    title={media.caption}
                  />
                </a>
              </li>
            );
          })}
        </ul>
        {this.instagramData.afterContent ? (
          <InnerHTML
            contents={decodePhpHtmlString(this.instagramData.afterContent)}
            class={[style.container, style.afterContent]}
          />
        ) : null}
      </section>
    );
  }
}
